import Nav from '../../components/Nav/Nav.js';
import './style.css';
import Footer from '../../components/Footer/Footer.js';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import images from '../../images.js'

function Gallerij() {

    document.title = 'Vroomshoopse Boys 6 | Gallerij';
    
    const randomImages = images.sort((a, b) => 0.5 - Math.random()) 

    return(
        <>
            <div className='page-gallerij'>
                <header style={{backgroundImage: 'url("/assets/images/header-bg-2.jpg")'}}>
                    <Nav />
                    <div className='container'>
                        <div className='header-content'>
                            <h1><span>Gallerij</span></h1>
                        </div>
                    </div>
                    <div className='transition'></div>
                </header>

                <main>
                    <section>
                        <div className='container'>
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                        >
                            <Masonry
                                gutter='20px'
                            >
                                {randomImages.map((image, index) => {
                                    return (
                                        <img key={index} src={image} alt='Vroomshoopse Boys 6'/>
                                    )
                                })}
                            </Masonry>
                        </ResponsiveMasonry>
                        </div>
                    </section>
                </main>

                <Footer />
            </div>
        </>
    )

}

export default Gallerij;