import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/home/Home.js'
import Selectie from './pages/selectie/Selectie.js'
import Programma from './pages/programma/ProgrammaPage.js';
import Gallerij from './pages/gallerij/Gallerij.js';
import ScrollToTop from './ScrollToTop.js';
import { getAnalytics } from "firebase/analytics";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2bJ0puW9fX_zELJQeJlSqjnU6AdcRINM",
  authDomain: "boys-6.nl",
  projectId: "boys-6",
  storageBucket: "boys-6.appspot.com",
  messagingSenderId: "952177619107",
  appId: "1:952177619107:web:f6c17678f84120bc548f16",
  measurementId: "G-5V250RDRWX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  const [isCookies, setIsCookies] = useState(false);

  function FirebaseAnalytics() {
    const location = useLocation();
    useEffect(() => {
        const analytics = window.firebase && window.firebase.analytics;
        if (typeof analytics === "function") {
            const page_path = location.pathname + location.search;
            analytics().setCurrentScreen(page_path);
            analytics().logEvent("page_view", { page_path });
        }
    }, [location]);
    return null;
  }

  return (
    <>
      <Router>
        <CookieConsent 
          enableDeclineButton={true}
          containerClasses='cookie-consent'
          disableStyles={true} buttonClasses='btn cookie-accept'
          declineButtonClasses='btn cookie-decline'
          buttonWrapperClasses='cookie-btn-wrapper'
          declineButtonText='Afwijzen'
          buttonText='Accepteren'
          onAccept={() => {
            setIsCookies(true);
            window.location.reload();
        }}>
          <p>Deze website gebruikt cookies van Google analytics.</p>
        </CookieConsent>
        {isCookies ? FirebaseAnalytics : ''}
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/selectie' element={<Selectie />} />
          <Route path='/programma' element={<Programma />} />
          <Route path='/gallerij' element={<Gallerij />} />
          <Route path='*' element={null} /> 
        </Routes>
      </Router>
    </>
  );
}

export default App;
