import './style.css';

function Footer() {


    return(
        <footer style={{backgroundImage: 'url("./assets/images/vhboys-outline.svg")'}}>
            <div className='container'>
                <div className='logo'>
                    <img src='/assets/images/vh-boys-logo.png' alt='Vroomshoopse Boys logo' />
                    <span>Boys 6</span>
                </div>
                <p className='copyright'>Copyright &#169; {new Date().getFullYear()} Vroomshoopse Boys 6</p>
            </div>
        </footer>
    )
}

export default Footer;