import Nav from '../../components/Nav/Nav';
import './style.css';
import Footer from '../../components/Footer/Footer';
import players from '../../players.js'

function Selectie() {

    document.title = 'Vroomshoopse  Boys 6 | Selectie';

    const staff = players.filter((player) => {
        return player.position !== 'aanvaller' &&
        player.position !== 'middenvelder' &&
        player.position !== 'verdediger' &&
        player.position !== 'keeper'
    });

    const defenders = players.filter((player) => {
        return player.position === 'verdediger' ||
        player.position === 'keeper'
    });

    const midfielders = players.filter((player) => {
        return player.position === 'middenvelder'
    });

    const attackers = players.filter((player) => {
        return player.position === 'aanvaller'
    });

    return(
        <>
            <div className='page-selectie'>
                <header style={{backgroundImage: 'url("/assets/images/header-bg-2.jpg")'}}>
                    <Nav />
                    <div className='container'>
                        <div className='header-content'>
                            <h1><span>Selectie</span></h1>
                        </div>
                    </div>
                    <div className='transition'></div>
                </header>

                <main>
                    <section className='staff'>
                        <div className='container'>
                            <h2>Staff</h2>
                            <div className='grid'>
                                {staff.map((player, index) => (
                                    <div key={index} className='item'>
                                        <div className='player-image'>
                                            <img src={player.picture} alt={player.name} />
                                        </div>
                                        <div className="item-inner">
                                            <p className='name'>{player.name}</p>
                                            <p className='nickname'>{player.nickname}</p>
                                        </div>
                                        <p className='position'>{player.position}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <section className='defenders'>
                        <div className='container'>
                            <h2>Verdedigers</h2>
                            <div className='grid'>
                                {defenders.map((player, index) => (
                                    <div key={index} className='item'>
                                        <div className='player-image'>
                                            <img src={player.picture} alt={player.name} />
                                        </div>
                                        <div className="item-inner">
                                            <p className='name'>{player.name}</p>
                                            <p className='nickname'>{player.nickname}</p>
                                        </div>
                                        <p className='position'>{player.position}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <section className='midfielders'>
                        <div className='container'>
                            <h2>Middenvelders</h2>
                            <div className='grid'>
                                {midfielders.map((player, index) => (
                                    <div key={index} className='item'>
                                        <div className='player-image'>
                                            <img src={player.picture} alt={player.name} />
                                        </div>
                                        <div className="item-inner">
                                            <p className='name'>{player.name}</p>
                                            <p className='nickname'>{player.nickname}</p>
                                        </div>
                                        <p className='position'>{player.position}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <section className='attackers'>
                        <div className='container'>
                            <h2>Aanvallers</h2>
                            <div className='grid'>
                                {attackers.map((player, index) => (
                                    <div key={index} className='item'>
                                        <div className='player-image'>
                                            <img src={player.picture} alt={player.name} />
                                        </div>
                                        <div className="item-inner">
                                            <p className='name'>{player.name}</p>
                                            <p className='nickname'>{player.nickname}</p>
                                        </div>
                                        <p className='position'>{player.position}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </main>

                <Footer />
            </div>
        </>
    )

}

export default Selectie;