import { useState } from 'react';
import './style.css'
import { Link, NavLink } from 'react-router-dom';

function Nav() {

    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        if(window.innerWidth < 992) {
            setMenuOpen(!isMenuOpen)
        }
    }

    return(
        <nav>
            <div className='container'>
                <Link to='/'>
                    <div className='logo'>
                        <img src='/assets/images/vh-boys-logo.png' alt='Vroomshoopse Boys logo' />
                        <span>Boys 6</span>
                    </div>
                </Link>
                <div className={'menu ' + (isMenuOpen ? 'menu-open' : '')}>    
                    <ul>
                        <li><NavLink onClick={toggleMenu} to={'/'}><span>Home</span></NavLink></li>
                        <li><NavLink onClick={toggleMenu} to={'/selectie'}><span>Selectie</span></NavLink></li>
                        <li><NavLink onClick={toggleMenu} to={'/programma'}><span>Programma</span></NavLink></li>
                        <li><NavLink onClick={toggleMenu} to={'/gallerij'}><span>Gallerij</span></NavLink></li>
                    </ul>
                </div>
                <div onClick={toggleMenu} className={'burger ' + (isMenuOpen ? 'menu-open' : '')}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </nav>
    )

}

export default Nav;