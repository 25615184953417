import Nav from '../../components/Nav/Nav.js';
import './style.css';
import Footer from '../../components/Footer/Footer.js';
import Programma from '../../components/Programma/Programma';

function ProgrammaPage() {

    document.title = 'Vroomshoopse Boys 6 | Programma';

    return(
        <>
            <div className='page-programma'>
                <header style={{backgroundImage: 'url("/assets/images/header-bg-2.jpg")'}}>
                    <Nav />
                    <div className='container'>
                        <div className='header-content'>
                            <h1><span>Programma</span></h1>
                        </div>
                    </div>
                    <div className='transition'></div>
                </header>

                <main>
                    <section>
                        <div className='container'>
                            <Programma hasMax={false} />
                        </div>
                    </section>
                </main>

                <Footer />
            </div>
        </>
    )

}

export default ProgrammaPage;