const players = [
    {
        name: 'Marthijn Staarman',
        nickname: 'Tinus',
        position: 'keeper',
        picture: '/assets/images/players/boys6-marthijn.jpeg'
    }, {
        name: 'Rutger van Schouwen',
        nickname: 'Rudy',
        position: 'middenvelder',
        picture: '/assets/images/players/boys6-rutger.jpeg'
    }, {
        name: 'Jeroen Breukelman',
        nickname: 'Joene',
        position: 'middenvelder',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Robbin Meijerink',
        nickname: 'Julio',
        position: 'trainer',
        picture: '/assets/images/players/boys6-robbin.jpeg'
    }, {
        name: 'Jesse Greveling',
        nickname: 'Grevel',
        position: 'verdediger',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Marc Veldhuis',
        nickname: 'Mek',
        position: 'middenvelder',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Ali Fouroughi',
        nickname: 'Aaltje',
        position: 'aanvaller',
        picture: '/assets/images/players/boys6-ali.jpeg'
    }, {
        name: 'Arnaut Brunink',
        nickname: 'Arnie',
        position: 'aanvaller',
        picture: '/assets/images/players/boys6-arnaut.jpeg'
    }, {
        name: 'Bertho Veltink',
        nickname: null,
        position: 'verdediger',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Dani van Laar',
        nickname: 'Bami',
        position: 'aanvaller',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Mick Meijerink',
        nickname: null,
        position: 'vlagger',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Niek Vos',
        nickname: 'Vosje',
        position: 'verdediger',
        picture: '/assets/images/players/boys6-niek.jpeg'
    }, {
        name: 'Deon Runhart',
        nickname: 'Donnie',
        position: 'middenvelder',
        picture: '/assets/images/players/boys6-deon.jpeg'
    }, {
        name: 'Twan Veldhuis',
        nickname: null,
        position: 'verdediger',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Jesse Runhart',
        nickname: null,
        position: 'aanvaller',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Joran van der Veen',
        nickname: null,
        position: 'aanvaller',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Jorian Bolks',
        nickname: 'Shaggie Bolks',
        position: 'middenvelder',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Justin Dommerholt',
        nickname: 'Donkel',
        position: 'verdediger',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Justin Nijmeijer',
        nickname: null,
        position: 'aanvaller',
        picture: '/assets/images/players/boys6-justin.jpeg'
    }, {
        name: 'Michel Keizer',
        nickname: 'Keizer',
        position: 'assistent trainer',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Leroy Olsman',
        nickname: null,
        position: 'verdediger',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Michel Schuurman',
        nickname: null,
        position: 'verdediger',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Michel Bosch',
        nickname: null,
        position: 'aanvaller',
        picture: '/assets/images/no-image.svg'
    }, {
        name: 'Mike Zandbergen',
        nickname: null,
        position: 'verdediger',
        picture: '/assets/images/players/boys6-mike.jpeg'
    }, {
        name: 'Wessel van den Bosch',
        nickname: 'Wessels',
        position: 'aanvaller',
        picture: '/assets/images/players/boys6-wessel.jpeg'
    },
]

// {
//     name: 'Daniel Lambers',
//     nickname: null,
//     position: 'middenvelder',
//     picture: '/assets/images/no-image.svg'
// }

export default players