const images = [
    '/assets/images/gallery/gallery-1.jpg',
    '/assets/images/gallery/gallery-2.jpg',
    '/assets/images/gallery/gallery-3.jpg',
    '/assets/images/gallery/gallery-4.jpg',
    '/assets/images/gallery/gallery-5.jpg',
    '/assets/images/gallery/gallery-6.jpg',
    '/assets/images/gallery/gallery-7.jpg',
    '/assets/images/gallery/gallery-8.jpg',
    '/assets/images/gallery/gallery-9.jpg',
    '/assets/images/gallery/gallery-10.jpg',
    '/assets/images/gallery/gallery-11.jpg',
    '/assets/images/gallery/gallery-12.jpg',
    '/assets/images/gallery/gallery-13.jpg',
    '/assets/images/gallery/gallery-14.jpg',
    '/assets/images/gallery/gallery-15.jpg',
    '/assets/images/gallery/gallery-16.jpg',
    '/assets/images/gallery/gallery-17.jpg',
    '/assets/images/gallery/gallery-18.jpg',
    '/assets/images/gallery/gallery-19.jpeg',
    '/assets/images/gallery/gallery-20.jpeg',
    '/assets/images/gallery/gallery-21.jpeg',
    '/assets/images/gallery/gallery-22.jpeg',
    '/assets/images/gallery/gallery-23.jpeg',
    '/assets/images/gallery/gallery-24.jpeg',
    '/assets/images/gallery/gallery-25.jpeg',
]

export default images