import { useState } from 'react';
import Nav from '../../components/Nav/Nav';
import TeamCarousel from '../../components/TeamCarousel/TeamCarousel';
import Stand from '../../components/Stand/Stand';
import UpcomingGame from '../../components/UpcomingGame/UpcomingGame';
import './style.css';
import Programma from '../../components/Programma/Programma';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';

function Home() {

    document.title = 'Vroomshoopse Boys 6';

    const [activeDetail, setActiveDetail] = useState('programma');

    const handleDetail = (detail) => {
        setActiveDetail(detail)
    }

    return(
        <>
            <header style={{backgroundImage: 'url("/assets/images/teamfoto-expanded-small.jpg")'}}>
                <Nav />
                <div className='container'>
                    <div className='header-content'>
                        <h1 className='title'>Een bierteam met een <span>voetbalprobleem</span></h1>
                        <h1 className='title-mobile'>Een bierteam<br/>met een<br/>voetbalprobleem</h1>
                    </div>
                    <div className='header-items'>
                        <div>
                            <div>
                                <h3>Volg ons!</h3>
                                <a className='btn btn-icon' target='blank' href='https://www.facebook.com/nietwinnenisookwinnen'>
                                    <span>Facebook</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/></svg>
                                </a>
                            </div>
                        </div>
                        <div className='upcoming'>
                            <UpcomingGame />
                        </div>
                    </div>
                </div>
                <div className='transition'></div>
            </header>
            <main>

                <section className='info p-normal'>
                    <div className='pictures'>
                        <div className='pictures-wrapper'>
                            <div>
                                <div className='picture'>
                                    <img src='/assets/images/teamfoto-small.jpg' alt='Vroomshoopse Boys 6' />
                                </div>
                            </div>
                            <div>
                                <div className='picture'>
                                    <img src='/assets/images/gallery/gallery-11.jpg' alt='Vroomshoopse Boys 6' />
                                </div>
                                <Link className='gallerij' to='/gallerij'>
                                    <img src='/assets/images/gallerij.png' alt='gallerij' />
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M686-450H160v-60h526L438-758l42-42 320 320-320 320-42-42 248-248Z"/></svg>
                                    </span>
                                </Link>
                            </div>
                            <div>
                                <div className='picture'>
                                    <img src='/assets/images/gallery/gallery-13.jpg' alt='Vroomshoopse Boys 6' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className='cta'>
                    <div className='container'>
                        <div className='cta-content'>
                            <h2>Komt Donkel ook te laat voor 'De Wedstrijd van je Leven' live op ESPN?</h2>
                            <p>Vroomshoopse Boys 6 is in de running voor 'De Wedstrijd van je Leven'. Het winnende team speelt een wedstrijd tegen oud-profs van <a href='https://www.fcderebellen.nl/selectie/' target='blank'>FC de Rebellen</a> live op ESPN, inclusief interviews, voor- en nabeschouwing en commentaar.</p>
                            <a className='btn' href='https://www.wedstrijdvanjeleven.nl/#/club/bbkt86s-t557204005/' target='blank'>
                                <span>Stem nu</span>
                            </a>
                        </div>
                        <div className='cta-image-wrapper'>
                            <div className='cta-image' style={{backgroundImage: 'url("/assets/images/wedstrijdvanjeleven-image.webp")'}}></div>
                        </div>
                    </div>
                </section>

                <section className='team p-normal'>
                    <div className='container'>
                        <div className='team-header'>
                            <h2>De helden van Boys 6</h2>
                            <Link className='btn' to='/selectie'>
                                <span>Bekijk selectie</span>
                            </Link>
                        </div>
                    </div>
                    <TeamCarousel />
                </section>

                <section className='details'>
                    <div className='container'>
                        <div className='details-header'>
                            <div>
                                <h2 onClick={() => {handleDetail('programma')}} className={activeDetail === 'programma' ? 'active' : ''}>Programma</h2>
                                <h2 onClick={() => {handleDetail('stand')}} className={activeDetail === 'stand' ? 'active' : ''}>Stand</h2>
                            </div>
                            <Link className='btn' to='/programma'><span>Volledige programma</span></Link>
                        </div>
                        <div className={activeDetail === 'programma' ? 'detail active' : 'detail'}>
                            <Programma hasMax={true} />
                        </div>
                        <div className={activeDetail === 'stand' ? 'detail active' : 'detail'}>
                            <Stand />
                        </div>
                    </div>
                </section>

                <section className='verslag p-normal'>
                    <div className='container'>
                        <div className='left'>
                            <h2>Wedstrijdverslagen</h2>
                            <p>Elke zaterdag doet Donnie weer zijn uiterste best om een ge-wel-dig verslag te schrijven. Of hij hier nou talent voor heeft, of dat het komt door het drinken van heel veel Klok bier, iedereen kijkt er naar uit.<br/><br/>Alle verslagen zijn op Facebook te vinden, Volg ons!</p>
                            <div className='button-row'>
                                <a className='btn btn-icon' target='blank' href='https://www.facebook.com/nietwinnenisookwinnen'>
                                    <span>Facebook</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/></svg>
                                </a>
                            </div>
                        </div>
                        {/* <div className='right'>
                            <div className="fb-post" data-href="https://www.facebook.com/nietwinnenisookwinnen/posts/pfbid02j3ZbBxWvtzwSSrxrWQD8Y58Po1MzYu7fP8XpdY43GnLpTtBMvNXPbBuMthw9fJubl" data-width="500" data-show-text="true"></div>
                        </div> */}
                    </div>
                </section>

            </main>

            <Footer />
        </>
    )

}

export default Home;