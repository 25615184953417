import { useEffect, useState } from "react";
import './style.css';

function Programma({hasMax = true}) {

    const [isLoading, setIsLoading] = useState(true);
    const [games, setGames] = useState();

    const url = `https://data.sportlink.com/programma?sorteervolgorde=datum${hasMax ? '&aantalregels=4' : ''}&gebruiklokaleteamgegevens=JA&aantaldagen=999&eigenwedstrijden=JA&thuis=JA&uit=JA&client_id=7mQjAvK3Jp&teamcode=164043`

    useEffect(() => {
        const fetchGames = async () => {
            const response = await fetch(url);
            const data = await response.json()
            setGames(data);
            setIsLoading(false);
        };
        fetchGames();
        console.log(games);
    }, [url]);


    if(isLoading) {
        return (
            <svg className="loading" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z"/></svg>    
        )
    }

    if(games.length === 0) {
        return(
            <p>Nog geen programma</p>
        )
    }

    return(
        <div className="programma">
            {games.map((game, index) => {
                return (
                    <div key={index} className="card">
                        <div className="card-team">
                            <img src={game.thuisteamlogo} alt={game.thuisteam + ' logo'} />
                            <p>{game.thuisteam}</p>
                        </div>
                        <div className="card-datetime">
                            <p className="time">{game.aanvangstijd}</p>
                            <p className="date">{game.datum}</p>
                        </div>
                        <div className="card-team">
                            <img src={game.uitteamlogo} alt={game.uitteam + ' logo'}/>
                            <p>{game.uitteam}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Programma;