import './style.css'
import players from '../../players.js'

function TeamCarousel() {

    return(
        <div className='marquee'>
            <div className='track'>
                {players.map((player, index) => (
                    <div key={index} className='item'>
                        <div className='player-image'>
                            <img src={player.picture} alt={player.name} />
                        </div>
                        <div className="item-inner">
                            <p className='name'>{player.name}</p>
                            <p className='nickname'>{player.nickname}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className='track'>
                {/* Mirror conntent */}
                {players.map((player, index) => (
                    <div key={index} className='item' aria-hidden='true'>
                        <div className='player-image'>
                            <img src={player.picture} alt={player.name} />
                        </div>
                        <div className="item-inner">
                            <p className='name'>{player.name}</p>
                            <p className='nickname'>{player.nickname}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )

}

export default TeamCarousel;