import { useEffect, useState } from "react";

function UpcomingGame() {

    const [isLoading, setIsLoading] = useState(true);
    const [upcomingGame, setUpcomingGame] = useState();

    useEffect(() => {
        const fetchGames = async () => {
            const response = await fetch('https://data.sportlink.com/programma?sorteervolgorde=datum&aantalregels=1&gebruiklokaleteamgegevens=JA&aantaldagen=999&eigenwedstrijden=JA&thuis=JA&uit=JA&client_id=7mQjAvK3Jp&teamcode=164043');
            const data = await response.json()
            let game = data[0];
            setUpcomingGame(game);
            setIsLoading(false);
        };
        fetchGames();
    }, []);

    if(isLoading) {
        return <p>Laden...</p>
    }

    if(!upcomingGame) {
        return <p className="no-game">Nog geen aankomende wedstrijd...</p>
    }

    return(
        <>
            <div className="card-team">
                <img src={upcomingGame.thuisteamlogo} alt={upcomingGame.thuisteam + ' logo'} />
                <p>{upcomingGame.thuisteam}</p>
            </div>
            <div className="card-datetime">
                <p className="time">{upcomingGame.aanvangstijd}</p>
                <p className="date">{upcomingGame.datum}</p>
            </div>
            <div className="card-team">
                <img src={upcomingGame.uitteamlogo} alt={upcomingGame.uitteam + ' logo'}/>
                <p>{upcomingGame.uitteam}</p>
            </div>
        </>
    )
}

export default UpcomingGame;