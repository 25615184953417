import { useEffect, useState } from "react";
import './style.css'

function Stand() {

    const [isLoading, setIsLoading] = useState(true);
    const [stand, setStand] = useState();

    useEffect(() => {
        const fetchGames = async () => {
            const response = await fetch('https://data.sportlink.com/poulestand?poulecode=752973&gebruiklokaleteamgegevens=NEE&client_id=7mQjAvK3Jp');
            const data = await response.json()
            setStand(data);
            setIsLoading(false);
        };
        fetchGames();
    }, []);

    if(isLoading) {
        return <svg className="loading" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z"/></svg>    
    }

    return(
        <>
            {stand.map((team, index) => {
                return(
                    <div className={team.eigenteam === "true" ? "stand-team own-team" : "stand-team"} key={index}>
                        <div className="team-info">
                            <span className="team-position">{team.positie}</span>
                            <span className="team-name">{team.teamnaam}</span>
                        </div>
                        <div className="team-numbers">
                            <span className="team-played">{team.gespeeldewedstrijden}</span>
                            <span className="team-points">{team.punten}</span>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default Stand;